import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LPlan,
  CAnchorNav,
  CRoomMedia,
  ConceptMedia,
  LWifi,
  LStayContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'ROOM',
            sub: '客室一覧',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/floor/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/kv__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/stay/floor/kv02.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/kv02__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/stay/floor/kv03.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/kv03__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/stay/floor/kv04.png',
              },
              imgSp: {
                src: '/assets/images/stay/floor/kv04__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'ご宿泊',
                path: '/stay/',
              },
            ],
            current: {
              label: '客室',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect02 u_bgWhite">
        <LWrap>
          <CAnchorNav
            colSp={1}
            data={[
              {
                title: (
                  <>
                    67F
                    <br />
                    スカイリゾートフロア「ザ・トップ」
                  </>
                ),
                link: {
                  href: '#topsky',
                },
              },
              {
                title: (
                  <>
                    65F 〜 66F
                    <br />
                    スカイリゾートフロア「ザ・クラブ」
                  </>
                ),
                link: {
                  href: '#club',
                },
                label: 'クラブラウンジ利用対象',
              },
              {
                title: (
                  <>
                    65F 〜 66F
                    <br />
                    スイートルーム／和室
                  </>
                ),
                link: {
                  href: '#suite_floor',
                },
                label: 'クラブラウンジ利用対象',
              },
              {
                title: (
                  <>
                    60F 〜 64F
                    <br />
                    スカイリゾートフロア「アトリエ」
                  </>
                ),
                link: {
                  href: '#atelier_floor',
                },
              },
              {
                title: (
                  <>
                    57F 〜 59F
                    <br />
                    レギュラーフロア
                  </>
                ),
                link: {
                  href: '#57_regular_floor',
                },
              },
              {
                title: (
                  <>
                    52F 〜 56F
                    <br />
                    レギュラーフロア
                  </>
                ),
                link: {
                  href: '#52_regular_floor',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <div className="u_bgGrad l_sect">
        <LWrap>
          <CRoomMedia
            data={[
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_topsky.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_topsky02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_topsky03.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_topsky04.png',
                      alt: '',
                    },
                  },
                ],
                title: (
                  <>
                    <div id="topsky">スカイリゾートフロア「ザ・トップ」</div>
                  </>
                ),
                floor: '67F',
                text: (
                  <>
                    客室最上階、 スカイリゾートフロア「ザ・トップ」。
                    <br />
                    地上約263mから眺める横浜の夜景が思い出をさらに輝かせます。
                  </>
                ),
                anchorList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_topsky_anchor.png',
                      alt: '',
                    },
                    link: '/stay/floor/top_floor/#comfortDouble',
                    title: {
                      en: 'COMFORT DOUBLE',
                      ja: 'コンフォートダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_topsky_anchor02.png',
                      alt: '',
                    },
                    link: '/stay/floor/top_floor/#deluxeDouble',
                    title: {
                      en: 'DELUXE DOUBLE',
                      ja: 'デラックスダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_topsky_anchor03.png',
                      alt: '',
                    },
                    link: '/stay/floor/top_floor/#premiumDouble',
                    title: {
                      en: 'PREMIUM DOUBLE',
                      ja: 'プレミアムダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_topsky_anchor04.png',
                      alt: '',
                    },
                    link: '/stay/floor/top_floor/#comfortTwin',
                    title: {
                      en: 'COMFORT TWIN',
                      ja: 'コンフォートツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_topsky_anchor05.png',
                      alt: '',
                    },
                    link: '/stay/floor/top_floor/#deluxeTwin',
                    title: {
                      en: 'DELUXE TWIN',
                      ja: 'デラックスツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_topsky_anchor06.png',
                      alt: '',
                    },
                    link: '/stay/floor/top_floor/#cornerTwin',
                    title: {
                      en: 'CORNER TWIN',
                      ja: 'コーナーツイン',
                    },
                  },
                ],
                btn: {
                  link: '/stay/floor/top_floor/',
                  text: 'スカイリゾートフロア「ザ・トップ」',
                },
              },
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_club.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_club02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_club03.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_club04.png',
                      alt: '',
                    },
                  },
                ],
                label: (
                  <>
                    <div id="club">クラブラウンジ利用対象</div>
                  </>
                ),
                title: <>スカイリゾートフロア「ザ・クラブ」</>,
                floor: '65F 〜 66F',
                text: (
                  <>
                    スイートルームを含む、最高級カテゴリーのクラブフロア。
                    <br />
                    専用ラウンジでは、チェックイン・チェックアウトの他、お飲物やスナックをご用意しております。
                  </>
                ),
                bnr: {
                  src: '/assets/images/stay/floor/img_loungebnr.png',
                  alt: 'クラブラウンジ利用対象',
                },
                anchorList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_club_anchor.png',
                      alt: '',
                    },
                    link: '/stay/floor/club_floor/#comfortDouble',
                    title: {
                      en: 'COMFORT DOUBLE',
                      ja: 'コンフォートダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_club_anchor02.png',
                      alt: '',
                    },
                    link: '/stay/floor/club_floor/#cornerDouble',
                    title: {
                      en: 'CORNER DOUBLE',
                      ja: 'コーナーダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_club_anchor03.png',
                      alt: '',
                    },
                    link: '/stay/floor/club_floor/#comfortTwin',
                    title: {
                      en: 'COMFORT TWIN',
                      ja: 'コンフォートツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_club_anchor04.png',
                      alt: '',
                    },
                    link: '/stay/floor/club_floor/#deluxeTwin',
                    title: {
                      en: 'DELUXE TWIN',
                      ja: 'デラックスツイン',
                    },
                  },
                ],
                btn: {
                  link: '/stay/floor/club_floor/',
                  text: 'スカイリゾートフロア「ザ・クラブ」',
                },
              },
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_suite02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_suite.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_suite03.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_suite04.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_suite05.png',
                      alt: '',
                    },
                  },
                ],
                label: (
                  <>
                    <div id="suite_floor">クラブラウンジ利用対象</div>
                  </>
                ),
                title: <>スイートルーム／和室</>,
                floor: '65F 〜 66F',
                text: (
                  <>
                    スカイリゾートフロア「ザ・クラブ」の中でも、65～290平方メートルのゆとりある贅を尽くしたスイートルームです。
                  </>
                ),
                bnr: {
                  src: '/assets/images/stay/floor/img_loungebnr.png',
                  alt: 'クラブラウンジ利用対象',
                },
                anchorList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_suite_anchor.png',
                      alt: '',
                    },
                    link: '/stay/floor/suite_floor/#comfortSuite',
                    title: {
                      en: 'COMFORT SUITE',
                      ja: 'コンフォートスイート',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_suite_anchor02.png',
                      alt: '',
                    },
                    link: '/stay/floor/suite_floor/#executiveSuite',
                    title: {
                      en: 'EXECUTIVE SUITE',
                      ja: 'エグゼクティブスイート',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_suite_anchor03.png',
                      alt: '',
                    },
                    link: '/stay/floor/suite_floor/#presidentalSuite',
                    title: {
                      en: (
                        <>
                          PRESIDENTIAL <br className="u_sp" />
                          SUITE
                        </>
                      ),
                      ja: 'プレジデンシャルスイート',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_suite_anchor04.png',
                      alt: '',
                    },
                    link: '/stay/floor/suite_floor/#japaneseSuite',
                    title: {
                      en: 'JAPANESE SUITE',
                      ja: '和室スイート',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_suite_anchor05.png',
                      alt: '',
                    },
                    link: '/stay/floor/suite_floor/#royalSuite',
                    title: {
                      en: 'ROYAL SUITE',
                      ja: 'ロイヤルスイート',
                    },
                  },
                ],
                btn: {
                  link: '/stay/floor/suite_floor/',
                  text: 'スイートルーム／和室',
                },
              },
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_sky.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_sky02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_sky03.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_sky04.png',
                      alt: '',
                    },
                  },
                ],
                title: (
                  <>
                    <div id="atelier_floor">
                      スカイリゾートフロア「アトリエ」
                    </div>
                  </>
                ),
                floor: '60F 〜 64F',
                text: <>窓際に配置したカウンターから絶景をお楽しみください。</>,
                anchorList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_sky_anchor.png',
                      alt: '',
                    },
                    link: '/stay/floor/atelier_floor/#comfortDouble',
                    title: {
                      en: 'COMFORT DOUBLE',
                      ja: 'コンフォートダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_sky_anchor02.png',
                      alt: '',
                    },
                    link: '/stay/floor/atelier_floor/#deluxeDouble',
                    title: {
                      en: 'DELUXE DOUBLE',
                      ja: 'デラックスダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_sky_anchor03.png',
                      alt: '',
                    },
                    link: '/stay/floor/atelier_floor/#cornerDouble',
                    title: {
                      en: 'CORNER DOUBLE',
                      ja: 'コーナーダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_sky_anchor04.png',
                      alt: '',
                    },
                    link: '/stay/floor/atelier_floor/#comfortTwin',
                    title: {
                      en: 'COMFORT TWIN',
                      ja: 'コンフォートツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_sky_anchor05.png',
                      alt: '',
                    },
                    link: '/stay/floor/atelier_floor/#deluxeTwin',
                    title: {
                      en: 'DELUXE TWIN',
                      ja: 'デラックスツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_sky_anchor06.png',
                      alt: '',
                    },
                    link: '/stay/floor/atelier_floor/#cornerTwin',
                    title: {
                      en: 'CORNER TWIN',
                      ja: 'コーナーツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_sky_anchor07.png',
                      alt: '',
                    },
                    link: '/stay/floor/atelier_floor/#comfortSweet',
                    title: {
                      en: 'COMFORT SUITE',
                      ja: 'コンフォートスイート',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_sky_anchor08.png',
                      alt: '',
                    },
                    link: '/stay/floor/atelier_floor/#grandSweet',
                    title: {
                      en: 'GRAND SUITE',
                      ja: 'グランドスイート',
                    },
                  },
                ],
                btn: {
                  link: '/stay/floor/atelier_floor/',
                  text: 'スカイリゾートフロア「アトリエ」',
                },
              },
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_renewal_regular.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_renewal_regular02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_renewal_regular03.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_renewal_regular04.png',
                      alt: '',
                    },
                  },
                ],
                title: (
                  <>
                    <div id="57_regular_floor">レギュラーフロア</div>
                  </>
                ),
                floor: '57F 〜 59F',
                text: (
                  <>
                    開業当初から使用してる家具の再活用やエコアメニティの導入など、SDGsへの取り組みを積極的に行いながらリニューアル。
                    <br />
                    神奈川県花である「山ゆり」柄のベッドスローやアート、ブルーを基調とした内装で、港街ヨコハマを感じていただけます。
                  </>
                ),
                anchorList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_renewal_regular_anchor.png',
                      alt: '',
                    },
                    link: '/stay/floor/57_regular_floor/#comfortDouble',
                    title: {
                      en: 'COMFORT DOUBLE',
                      ja: 'コンフォートダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_renewal_regular_anchor02.png',
                      alt: '',
                    },
                    link: '/stay/floor/57_regular_floor/#cornerDouble',
                    title: {
                      en: 'CORNER DOUBLE',
                      ja: 'コーナーダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_renewal_regular_anchor03.png',
                      alt: '',
                    },
                    link: '/stay/floor/57_regular_floor/#comfortTwin',
                    title: {
                      en: 'COMFORT TWIN',
                      ja: 'コンフォートツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_renewal_regular_anchor04.png',
                      alt: '',
                    },
                    link: '/stay/floor/57_regular_floor/#deluxeTwin',
                    title: {
                      en: 'DELUXE TWIN',
                      ja: 'デラックスツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_renewal_regular_anchor05.png',
                      alt: '',
                    },
                    link: '/stay/floor/57_regular_floor/#cornerTwin',
                    title: {
                      en: 'CORNER TWIN',
                      ja: 'コーナーツイン',
                    },
                  },
                ],
                btn: {
                  link: '/stay/floor/57_regular_floor/',
                  text: 'レギュラーフロア',
                },
              },
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_regular.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_regular02.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_regular03.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_regular04.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_room_regular05.png',
                      alt: '',
                    },
                  },
                ],
                title: (
                  <>
                    <div id="52_regular_floor">レギュラーフロア</div>
                  </>
                ),
                floor: '52F 〜 56F',
                text: (
                  <>
                    ベーシックなレギュラーフロアには、ファミリールームやバリアフリールームなど、多彩な客室がございます。
                  </>
                ),
                anchorList: [
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_regular_anchor.png',
                      alt: '',
                    },
                    link: '/stay/floor/52_regular_floor/#comfortDouble',
                    title: {
                      en: 'COMFORT DOUBLE',
                      ja: 'コンフォートダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_regular_anchor02.png',
                      alt: '',
                    },
                    link: '/stay/floor/52_regular_floor/#cornerDouble',
                    title: {
                      en: 'CORNER DOUBLE',
                      ja: 'コーナーダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_regular_anchor03.png',
                      alt: '',
                    },
                    link: '/stay/floor/52_regular_floor/#modereteDouble',
                    title: {
                      en: 'MODERATE DOUBLE',
                      ja: 'モデレートダブル',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_regular_anchor04.png',
                      alt: '',
                    },
                    link: '/stay/floor/52_regular_floor/#comfortTwin',
                    title: {
                      en: 'COMFORT TWIN',
                      ja: 'コンフォートツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_regular_anchor05.png',
                      alt: '',
                    },
                    link: '/stay/floor/52_regular_floor/#deluxeTwin',
                    title: {
                      en: 'DELUXE TWIN',
                      ja: 'デラックスツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_regular_anchor06.png',
                      alt: '',
                    },
                    link: '/stay/floor/52_regular_floor/#cornerTwin',
                    title: {
                      en: 'CORNER TWIN',
                      ja: 'コーナーツイン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/stay/floor/img_regular_anchor07.png',
                      alt: '',
                    },
                    link: '/stay/floor/52_regular_floor/#familyRoom',
                    title: {
                      en: 'FAMILY ROOM',
                      ja: 'ファミリールーム',
                    },
                  },
                ],
                btn: {
                  link: '/stay/floor/52_regular_floor/',
                  text: 'レギュラーフロア',
                },
              },
            ]}
          />
        </LWrap>
      </div>
      <LPlan />
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LStayContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
